import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    firstname: {
        name: 'First name',
        required: true,
        maxlength: 16,
        format: /^(?=.{1,16}$)[a-zA-Z0-9]+((\ |\'|\-)?[a-z A-Z0-9])*$/,
        custom_message: 'Sorry, that\'s not a valid first name.',
    },
    lastname: {
        name: 'Last name',
        required: true,
        maxlength: 16,
        format: /^(?=.{1,16}$)[a-zA-Z0-9]+((\ |\'|\-)?[a-z A-Z0-9])*$/,
        custom_message: 'Sorry, that\'s not a valid last name.',
    },
    fullname: {
        name: 'Full name',
        required: true,
        maxlength: 32,
        format: /^(?=.{1,32}$)[a-zA-Z0-9]+((\ |\'|\-)?[a-z A-Z0-9])*$/,
        custom_message: 'Sorry, that\'s not a valid full name.',
    },
    email: {
        name: 'E-mail address',
        required: true,
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        custom_message: 'Sorry, that\'s not a valid e-mail address.',
    },
    mobile: {
        name: 'Mobile number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,   // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                              // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                    //     на value
            },
        ],
        format: /^\+?\d{9,12}$/,                                // после всех предпроверок и замен - value должно соответсвовать format
        custom_message: 'Sorry, that\'s not a valid mobile number.',
    },
    phone: {
        name: 'Contact number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,   // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                              // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                    //     на value
            },
        ],
        format: /^\+?\d{9,12}$/,                                // после всех предпроверок и замен - value должно соответсвовать format
        custom_message: 'Sorry, that\'s not a valid phone number.',
    },
    code: {
        name: 'SMS code',
        required: true,
    },
    login: {
        name: 'Username',
        required: true,
    },
    pass: {
        name: 'Password',
        required: true,
    },
    passnew: {
        name: 'Password',
        required: true,
        customPasswordCheck: true,
    },
    devicename: {
        name: 'Device name',
        required: true,
    },
})