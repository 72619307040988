class ValidatorClass {
    constructor(rules) {
        this.rules = rules
        this.error = null
    }

    passwordContainsCount(password, allowedChars) {
        var count = 0
        for (var i = 0; i < password.length; i++) {
            var char = password.charAt(i);
            if (allowedChars.indexOf(char) >= 0) { count++; }
        }
        return count;
    }

    passwordErrorString(password) {
        if (password.length < 8) {
             return 'Password must be at least 8 characters long'
        } else {
            const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            if (this.passwordContainsCount(password, uppercase) < 1) {
                return 'Password must contain an upper case character'
            }
            const lowercase = "abcdefghijklmnopqrstuvwxyz";
            if (this.passwordContainsCount(password, lowercase) < 1) {
                return 'Password must contain a lower case character'
            }
            const digits = "0123456789";
            if (this.passwordContainsCount(password, digits) < 1) {
                return 'Password must contain a number'
            }
            // const splChars ="!@#$%&*()";
            // if (this.passwordContainsCount(password, splChars) < 1) {
            //     return 'Password must contain a special character'
            // }
        }
        return null
    }

    isRuleExists(key) {
        return key in this.rules
    }

    isInvalid(key, value, custom_rules = {}) {
        this.error = null

        if (this.isRuleExists(key)) {
            const rule = {...this.rules[key], ...custom_rules}

            const value_exists = (Array.isArray(value) ? value.length : value)

            if (rule.required && !value_exists) {
                this.error = `Please, enter ${ rule.name }`
            } else if (rule.customPasswordCheck) {
                this.error = this.passwordErrorString(value)
                return this.error
            } else if (value_exists) {
                if (rule.maxlength && value.length > rule.maxlength) {
                    this.error = `${ rule.name } can't be longer than ${ rule.maxlength } characters`
                } else {
                    if (rule.replaces) {
                        for (let n = 0, len = rule.replaces.length; n < len; n++) {
                            const rule_replace = rule.replaces[n]

                            if (!rule_replace.format || rule_replace.format.test(value)) {
                                value = value.replace(rule_replace.pattern, rule_replace.replace)
                            } else {
                                this.error = `${ rule.name } format is invalid` || rule.custom_message
                                break
                            }
                        }
                    }

                    if (!this.error) {
                        if (rule.format && !rule.format.test(value)) {
                            this.error = `${ rule.name } format is invalid` || rule.custom_message
                        }
                    }
                }
            }

        } else {
            console.log(`Rule "${ key }" not found in validator`)
            throw new Error(`Rule "${ key }" not found in validator`)
        }

        return this.error
    }

    getError() {
        return this.error
    }

    mobile(phone) {
        let mobile = phone.replace(/\D/g, '')

        if (/^\s*\+/g.test(phone)) {
            mobile = '+' + mobile
        }

        return mobile
    }
}

export default ValidatorClass
