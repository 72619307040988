<template>
    <div class="logo">
        <router-link class="logo__link" :to="{name: 'dashboard'}">
            <img class="logo__image" src="./img/logo.png" alt="logo">
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.logo {
    display: flex;

    &__link {
        flex: 1 1 100%;
        max-width: 100%;

        display: flex;
    }

    &__image {
        width: 100%;
        object-fit: contain;
    }
}
</style>