<template>
    <form class="form">
        <slot></slot>
    </form>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.form {
    &__input {
        margin-bottom: 32px;
    }

    &__btns {
        margin-top: 57px;
        margin-bottom: 40px;
        text-align: center;
    }
}
</style>