<template>
    <div class="app-loader" :class="[fixed_class]">
        <app-svg />
    </div>
</template>

<script>
import appSvg from './components/app-svg'

const fixed_list = {
    desktop: true,
    tablet: true,
    mobile: true,
}

export default {
    components: {
        appSvg,
    },

    props: {
        fixed_on: { type: String, default: '' }, // desktop | tablet | mobile
    },

    computed: {
        fixed_class() {
            return this.fixed_on && this.fixed_on in fixed_list ? `fixed-on-${ this.fixed_on }` : false
        },
    },
}
</script>

<style lang="scss">
@mixin fixed-on() {
    svg {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
    }
}

.app-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $loader-bg-color;
    border-radius: inherit;
    margin: 0;
    padding: 20px;
    z-index: $z-index-loader;

    svg {
        content: "";
        width: 96px;
        height: 96px;
        max-width: 95%;
        max-height: 95%;
        object-fit: contain;

        circle {
            fill: $loader-color;
        }
    }

    &.fixed-on-desktop {
        @include fixed-on
    }

    &~* {
        .app-loader {
            display: none;
        }
    }
}
</style>
