<template>
    <transition name="err">
        <div class="app-error" v-show="message">
            <div class="msg">
                {{ message }}
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['message'],
}
</script>

<style lang="scss">
.app-error {
    width: 100%;
    height: auto;
    font-size: 20px;
    line-height: 1.1;
    color: $error-color;
    padding: 14px;
    background: $error-bg-color;
    transition: $transition-duration-primary;
    text-align: center;
    border-radius: $border-radius-primary;

    &.err-enter-active, &.err-leave-active {
        transition: all #{$transition-duration-primary / 2} linear;

        transform: rotateX(0deg);
        padding: 14px 14px;
    }

    &.err-enter, &.err-leave-to {
        transition: all #{$transition-duration-primary / 2} linear;

        transform: rotateX(90deg);
        padding: 0px 14px;
    }
}
</style>
