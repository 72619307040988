<template>
    <div class="app-input-sign"
         :class="{
             'app-input-sign--password': is_password,
             'app-input-sign--disabled': disabled,
             'app-input-sign--typing': typing,
             'app-input-sign--error': error,
             'app-input-sign--focused': focused,
         }"
         @click="focus"
    >

        <div class="app-input-sign__icon" :class="{'app-input-sign__icon--eye': is_password}" v-if="is_password" @click="togglePassword">
            <template v-if="!show_password">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="4" fill="#fff"/>
                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 12c5-8 13-8 18 0M3 12c5 8 13 8 18 0"/>
                </svg>
            </template>
            <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 9c.732 1.172 1.529 2.171 2.371 3M21 9c-.732 1.172-1.529 2.172-2.37 3m0 0L21 14.601M18.63 12c-1.293 1.271-2.692 2.138-4.13 2.601M5.371 12L3 14.601M5.371 12c1.292 1.271 2.691 2.138 4.13 2.601m4.999 0l1 3.399m-1-3.399c-1.65.532-3.35.532-5 0m0 0L8.5 18"/>
                </svg>
            </template>
        </div>

        <input
            :type="computed_type"
            :value="value"
            :disabled="disabled"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :readonly="readonly"

            class="app-input-sign__input"

            ref="field"

            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.enter="onEnter"
            @input="handleChange($event.target.value)"
        >

        <label class="app-input-sign__label" v-if="!error">
            <span class="app-input-sign__label-text">{{ label }}<i v-if="required">*</i></span>
        </label>

        <div class="app-input-sign__error-message" v-if="error">{{ error }}</div>
    </div>
</template>

<script>

export default {
    props: {
        value:          { required: false                },
        type:           { type: String,  default: 'text' },
        required:       { type: Boolean, default: false  },
        disabled:       { type: Boolean, default: false  },
        error:          {                default: false  },
        icon:           { type: String,  default: ''     },
        label:          { type: String,  default: ''     },
        password:       { type: Boolean, default: false  },
        maxlength:      { default: 100                   },
        placeholder:    { default: ''                    },
        debounce:       { type: Number,  default: 0      },
        readonly:       { type: Boolean, default: false  },

        password_helper: { type: Boolean, default: false },
    },

    data() {
        return {
            focused: false,
            debounce_timeout: null,
            show_password: false,
        }
    },

    computed: {
        typing() {
            return this.focused || (this.value || this.value === 0)
        },

        is_password() {
            return this.type === 'password'
        },

        computed_type() {
            if (this.type === 'password') {
                return this.show_password ? 'text' : 'password'
            } else {
                return this.type
            }
        },
    },

    methods: {
        togglePassword() {
            this.show_password = !this.show_password
        },

        focus() {
            this.$refs.field.focus()

            return this
        },

        select() {
            this.$refs.field.select()

            return this
        },

        handleFocus() {
            this.focused = true
        },

        handleBlur() {
            this.focused = false
        },

        handleChange(value) {
            this.$emit('input', value)
            this.emitDebounce('change', value)
        },

        onEnter($event) {
            this.$emit('input', $event.target.value)
            this.$emit('change', $event.target.value)
            this.$emit('onenter', $event.target.value)
            this.clearDebounce()
        },

        emitDebounce(event, value) {
            if (this.debounce) {
                this.clearDebounce()

                this.debounce_timeout = setTimeout(() => {
                    this.$emit(event, value)
                    this.debounce_timeout = null
                }, this.debounce)
            } else {
                this.$emit(event, value)
            }
        },
        clearDebounce() {
            if (this.debounce_timeout !== null) {
                clearTimeout(this.debounce_timeout)

                this.debounce_timeout = null
            }
        },
    },
}
</script>

<style lang="scss">
.app-input-sign {
    $root: &;

    position: relative;
    width: 100%;
    height: 48px;
    margin-bottom: 1.5em;

    font-family: $font-family-primary;

    &__input {
        display: block;
        width: 100%;
        height: 100%;

        padding: 12px 16px 8px;

        font-size: 20px;
        line-height: 1.2;
        color: $white;
        background-color: transparent;

        border: 1px solid transparent;
        border-bottom-color: $brand-color-secondary-dark;
        border-radius: 0;

        #{$root}--password & {
            padding-right: 16px + 24px + 16px;
        }

        &::placeholder {
            color: transparent;
        }

        &[type=password] {
            letter-spacing: normal;

            &::placeholder {
                letter-spacing: inherit;
            }
        }
    }

    &__label {
        position: absolute;
        top: calc(50% + 4px);
        left: 16px;

        max-width: calc(100% - 16px - 24px - 1px);

        font-weight: normal;

        transform: translateY(-50%);

        transition: $transition-duration-primary;

        font-size: 20px;
        line-height: 1.2;
        color: $white;

        &-text {
            position: relative;

            display: block;

            @include text-overflow();
        }

        i {
            color: $error-color;
            font-style: normal;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: $transition-duration-primary;
        }
    }

    &__error-message {
        position: absolute;
        bottom: calc(100% + 4px);
        left: 0;
        width: 100%;
        max-width: 100%;
        padding-left: 16px;
        font-size: 16px;
        line-height: 1.2;
        color: $error-color;
        text-align: left;

        @include text-overflow();
    }

    &__icon {
        position: absolute;
        top: 1px;
        bottom: 1px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            g, path {
                //stroke:;
                //fill:;
            }
        }

        &--eye {
            right: 16px;
            left: auto;
            -webkit-tap-highlight-color: transparent;

            cursor: pointer;
        }
    }

    &--typing & {
        &__label {
            display: none;
        }

        &__input {
            color: $white;
            border-bottom-color: transparent;
            border-width: 1px;
            background-color: rgba($brand-color-secondary-dark, 0.6);
        }

        &__icon {
            svg {
                g, path {
                    // stroke:;
                    // fill:;
                }
            }
        }
    }

    &--disabled {
        opacity: 0.4;
    }

    &--error & {
        &__input {
            background-color: rgba($error-color, 0.5);
            border-color: transparent;
        }

        &__label {
            color: $error-color;
        }

        &__icon {
            svg {
                g, path {
                    // stroke: $error-color;
                    // fill: $error-color,
                }
            }
        }
    }

}
</style>
