<template>
    <div class="sign-layout">
        <div class="sign-layout__header">

            <app-logo class="sign-layout__logo"/>

            <h1 class="sign-layout__title">
                <template v-if="slots.title"><slot name="title" /></template>
                <template v-else>Quality Management System</template>
            </h1>
            <h5 class="sign-layout__lead">
                <template v-if="slots.lead"><slot name="lead" /></template>
                <template v-else>Bringing Clarity to Quality</template>
            </h5>

        </div>

        <div class="sign-layout__body">

            <div class="sign-layout__content">
                <app-loader v-if="processing"></app-loader>

                <app-error class="sign-layout__error" :message="error_message" />

                <slot />
            </div>

        </div>
    </div>
</template>

<script>
import AppLoader from '@/components/app-loader'
import AppError from '@/components/app-error'
import AppLogo from '@/components/app-logo'

import errMessage from '@/helpers/err-message'

export default {
    props: {
        processing: { type: Boolean, default: false },
        error: { default: null },
    },
    data() {
        return {
            slots: this.$slots,
        }
    },
    components: {
        AppLoader,
        AppError,
        AppLogo,
    },
    computed: {
        error_message() {
            return this.error instanceof Object ? errMessage(this.error) : this.error
        },
    },
}
</script>

<style lang="scss">
.sign-layout {
    flex: 1 1 auto;

    display: flex;

    color: $white;

    &__header {
        flex: 1 1 50%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        position: relative;
        background-image: linear-gradient(-45deg, rgba($brand-color-primary, 0.8) -20%, $brand-color-primary-dark), url('./img/sign-background.png');
        background-size: cover;

        padding: 220px 135px;
        @include media-bp(ltp) {
            padding: 152px 48px;
        }

        @include fluid-type($laptop-size, 1920px, 48px, 135px, 'padding-left');
        @include fluid-type($laptop-size, 1920px, 48px, 135px, 'padding-right');
        // @include fluid-type(1084px, 1920px, 32px + 120px, 48px + 172px, 'padding-top');
        // @include fluid-type(1084px, 1920px, 32px + 120px, 48px + 172px, 'padding-bottom');
    }
    

    &__logo {
        position: absolute;
        top: 48px;
        max-width: 262px;

        @include media-bp(ltp) {
            top: 32px;
            max-width: 152px;
        }

        // @include fluid-type(1084px, 1920px, 32px, 48px, 'top');
        // @include fluid-type(1084px, 1920px, 152px, 262px, 'max-width');
    }

    &__title {
        // @include fluid-type(1084px, 1920px, 72px, 96px, 'font-size');
    }

    &__lead {
        // @include fluid-type(1084px, 1920px, 40px, 32px, 'font-size');
    }

    &__body {
        flex: 1 1 50%;
        position: relative;
        padding: 48px;
        background-color: black;

        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__content {
        width: 100%;
        max-width: 450px;
        text-align: center;

        font-size: 18px;
        line-height: 1.78;

        a {
            color: $white;
            line-height: 1.33;
            text-decoration: underline;
            &:hover {
                color: $brand-color-secondary;
                text-decoration: none;
            }
        }

        p {
            margin-bottom: 32px;
        }
    }

    &__error {
        margin-bottom: 32px;
    }

    @include media-bp(tab) {
        flex-direction: column;
        flex-wrap: nowrap;

        &__logo {
            position: static;
            max-width: 152px;
        }

        &__header {
            flex: 0 0 auto;
            padding: 24px 40px;
        }

        &__title {
            margin-top: 68px;
        }

        &__lead {
            margin-bottom: 120px - 24px;
        }

        &__body {
            flex: 0 0 auto;
            padding: 90px 40px;
        }

        &__content {
            margin: 0 auto;
        }
    }
}
</style>
